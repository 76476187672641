import CRCLayout from "./layouts/CRCLayout";
import InteriorBanner from "./components/InteriorBanner"
import MainContent from "../_common/components/Layout/MainContent"
import Container from "../_common/components/Layout/Container"
import FooterCallout from "./components/FooterCallout";
import MetaTags from "../_common/components/MetaTags";
import styles from './CRCResources.module.scss'
import ExternalDownloadLink from "../_common/components/ExternalDownloadLink";

export default function CRCResources() {
  return (
    <CRCLayout>
      <MetaTags
        title='Treatment & Educational Materials'
        description="Access educational materials, guidelines, and support resources for healthcare providers."
        ogUrl='https://www.krazatihcp.com/c/resources'
      />
      {/* Banner */}
      <InteriorBanner title="Resources" imageUrl="/banners/banner-resources.jpg" desktopImageUrl="/banners/banner-resources-desktop.jpg" />

      <MainContent>
        <div className={styles.resources}>
          <Container>
            <div>
              <h2 className={styles.pageHeading}>Resources for you and your patients</h2>
              <h3>Easily access downloadable guides and important information to use during treatment with KRAZATI.</h3>
            </div>
            <div className={styles.resourcesCards}>
              <div>
                <h4>Healthcare provider resources</h4>
                <img src="/assets/resources/resource-1.jpg" alt="Therapy Management Guide" />
                <h5>Therapy Management Guide</h5>
                <p>Digital resource designed to help guide your conversations with patients while managing their treatment. Topics include safety information, dosage modifications, counseling patients on drug-drug interactions, and more.</p>
                <div><a href="/pdfs/therapy-management-guide.pdf" className={styles.downloadButton} target="_blank"><img src="/assets/resources/img-download-white.svg" alt="" />Download</a></div>
              </div>
              <div>
                <h4 className={styles.hide}>&nbsp;</h4>
                <img src="/assets/resources/resource-2.jpg" alt="Dosing Flashcard" />
                <h5>Dosing Flashcard</h5>
                <p>Quick reference guide for dosing KRAZATI.</p>
                <div><a href="/pdfs/dosing-flashcard-crc.pdf" className={styles.downloadButton} target="_blank"><img src="/assets/resources/img-download-white.svg" alt="" />Download</a></div>
              </div>
              <div>
                <h4>Patient resources</h4>
                <img src="/assets/resources/resource-3.jpg" alt="Patient Brochure" />
                <h5>Patient Brochure</h5>
                <p>Brochure with information for patients and care partners about KRAZATI.</p>
                <div><a href="/pdfs/patient-brochure-crc.pdf" className={styles.downloadButton} target="_blank"><img src="/assets/resources/img-download-white.svg" alt="" />Download</a></div>
              </div>
            </div>
          </Container>
        </div>

        <div className={`${styles.resourcesMiratiAndMe}`}>
          <Container>
            <h2>Mirati & Me resources</h2>
            <div className={styles.flex}>
              <div className={styles.flexCards}>
                <div>
                  <h3>Savings Card</h3>
                  <p>Provides eligibility criteria and processing information for patient out-of-pocket costs related to KRAZATI.</p>
                  <ExternalDownloadLink url="https://copayportal.paysign.com/enrollment/8740088678/index.html" />
                </div>
              </div>
              <div className={styles.flexCards}>
                <div>
                  <h3>Mirati & Me Enrollment Form</h3>
                  <p>For HCPs and patients to fill out and enroll in the Mirati & Me Program.</p>
                  <ExternalDownloadLink url="https://www.miratiandme.com/wp-content/uploads/2022/12/Mirati-and-Me-English-Enrollment-Form-1.pdf" />
                </div>
              </div>
              <div className={styles.flexCards}>
                {/* <img src="/assets/resources/img-placeholder-02.jpg" alt="" /> */}
                <div>
                  <h3>Patient Support Services</h3>
                  <p>Reference for product information and available patient access and reimbursement resources.</p>
                  <a href="https://www.miratiandme.com/wp-content/uploads/2022/12/Mirati-and-Me-Patient-Brochure-1.pdf" target="_blank" rel="noreferrer">Download</a><img className={styles.iconDownload} src="/assets/resources/img-download.svg" alt="" />
                </div>
              </div>
              <div className={styles.flexCards}>
                {/* <img src="/assets/resources/img-placeholder-02.jpg" alt="" /> */}
                <div>
                  <h3>Distributor Sell Sheet</h3>
                  <p>Wholesaler distribution network details, including specialty pharmacy & distributor information and stocking codes.</p>
                  <a href="https://www.miratiandme.com/wp-content/uploads/2022/12/Mirati-and-Me-Distribution-Sell-Sheet.pdf" target="_blank" rel="noreferrer">Download</a><img className={styles.iconDownload} src="/assets/resources/img-download.svg" alt="" />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </MainContent>

      {/* Footer Callout */}
      <FooterCallout title="Learn about patient support services designed for you" link="/c/support" />
    </CRCLayout>
  )
}
