import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseLink, BaseText, BaseTitle, Box, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import { FaChevronRight } from 'react-icons/fa';

import { Card } from '../../Home/pages/Slide4';
import { Link } from 'react-router-dom';
import { getToken, sendEmail } from '../../../api';

const Slide1 = ({ setPageBackground, setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
    const swiperSlide = useSwiperSlide();
    const { isMobile } = useMobileDetect();
    const mobileBgWrapper = useRef();
    const mobileOverlay = 'linear-gradient( rgba(12, 22, 63, 0) 60%, rgba(1, 20, 96, 0.433)100%)';
    const desktopOverlay = 'linear-gradient(180deg, rgba(36,49,99,0) 0%,rgb(7, 13, 35, .9) 100%)';
    const slideOverlay = isMobile ? mobileOverlay : desktopOverlay;
    const [showPageContent, setShowPageContent] = useState(false);
    const slideBackground = `url(${isMobile ? '/assets/home-bg-full.webp' : '/assets/bg-home2.webp'}) no-repeat fixed center top / cover`;

    useActiveLink(setShowHeaderSecondaryMenu, 'contact-us');

    useEffect(() => {
        // getToken()
        // sendEmail()
    }, []);

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setCurrentSlide(1);
            setShowPageContent(true);
            setBackground(slideBackground);
            setOverlay(slideOverlay);
            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    return (
        <CustomContainer className={showPageContent ? 'visible' : 'hidden'} ref={isMobile ? mobileBgWrapper : null} isiconfig={isiconfig}>
            <CustomWrapper>
                <Title>
                    <BaseTitle>How can we&nbsp;help you?</BaseTitle>
                </Title>

                <CardContainer direction='column' padding='0 0.8rem;'>
                    <CardWrapper background='rgba(34, 40, 62, .5)' margin='0 0 0.8rem' padding='1.8rem 2rem'>
                        <Box justify='space-between' align='center'>
                            <img src='/assets/message-icon.webp' alt='Connect with Mirati' />
                            <Button as={Link} to='/n/about-kras-g12c'>
                                Medical Information <FaChevronRight />{' '}
                            </Button>
                        </Box>
                    </CardWrapper>

                    <CardWrapper className={isMobile ? 'rect' : ''} background='rgba(34, 40, 62, .5)' margin='0 0 0.8rem' padding='2.2rem 2rem'>
                        <Box justify='space-between' align='center'>
                            <img src='/assets/mail-icon.webp' alt='Mirati and Me logo' />
                            <Button as={Link} to='/n/contact-us'>
                                Connect With Mirati <FaChevronRight />{' '}
                            </Button>
                        </Box>
                    </CardWrapper>
                </CardContainer>

                <Box justify='center' align='center' padding='0 0.8rem' margin='0 0 4rem'>
                    <BaseText text-align='center' transform='uppercase' size={isMobile ? 1.7 : 3.2}>
                        To report an adverse reaction,{isMobile ? '' : <br />} please click{' '}
                        <BaseLink href='https://nextroveintakev1.my.site.com/s/IntakeWebForm?m=HCP' target='_blank' size={isMobile ? 1.7 : 3.2} color='#17C94E' weight='bold'>
                            HERE
                        </BaseLink>
                        {/* <BaseLink href="tel:1.844.MIRATI.1" size={isMobile ? 1.7 : 3.2} color="#17C94E" weight="bold"> 1.844.MIRATI.1</BaseLink> */}
                    </BaseText>
                </Box>
            </CustomWrapper>
        </CustomContainer>
    );
};

export default Slide1;

const CustomContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    padding: 0;
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;

const CustomWrapper = styled(Wrapper)`
    margin-top: 2rem;
    justify-content: center;

    img {
        width: fit-content;
        max-width: 4.4rem;
    }

    @media ${device.desktop} {
        margin-top: 4rem;
    }
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.6rem;
    padding: 0 3rem 0;

    @media ${device.desktop} {
        margin-bottom: 6rem;
    }
`;
const Button = styled(BaseLink)`
    padding: 0.8rem 1.6rem;
    background: #3246ff;
    color: white;
    border-radius: 1.4rem;
    font-size: 1.1rem;
    width: fit-content;
    height: auto;
    min-width: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg {
        position: absolute;
        right: 1.6rem;
        transition: all 0.2s linear;
    }

    &:hover {
        svg {
            right: 1.2rem;
        }
    }

    @media ${device.desktop} {
        min-width: 22rem;
    }
`;

const CardContainer = styled(Box)`
    @media ${device.tablet} {
        max-width: 60%;
    }

    @media ${device.laptop} {
        max-width: 50%;
    }

    @media ${device.desktop} {
        max-width: 94rem;
        margin-bottom: 7rem;
        flex-direction: row;
    }
`;

const CardWrapper = styled(Card)`
    height: 8rem;
    &:nth-of-type(1) {
        img {
            max-width: 6.5rem;
        }
    }

    &:nth-of-type(2) {
        img {
            max-width: 6.5rem;
        }
    }

    &:nth-of-type(3) {
        padding: 2.6rem 2rem 2.6rem 3.6rem;
        img {
            max-width: 3.2rem;
        }
    }
    @media ${device.desktop} {
        padding: 3.6rem;
        > div {
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            a {
                height: auto;
                align-items: center;
                justify-content: center;
                display: flex;
                font-size: 1.6rem;
            }

            img {
                max-width: 8rem;
                width: 8rem;
            }
        }

        &:nth-of-type(1) {
            img {
                max-width: 8rem;
            }
        }

        &:nth-of-type(2) {
            img {
                max-width: 8rem;
            }
        }

        &:nth-of-type(3) {
            padding: 2.6rem 2rem 2.6rem 3.6rem;
            img {
                max-width: 6.4rem;
            }
        }
    }
`;
