import { useCallback, useRef, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import ISI from '../components/ISI/ISI'
import StickyISI from '../components/ISI/StickyISI';
import Footer from '../../_common/components/Footer/Footer';
import CRCNavigation from '../../_common/components/Navigation/CRCNavigation';
import CRCIndication from '../../_common/components/Indication/CRCIndication';
import useWindowSize from '../../_hooks/useWindowSize';
import SiteSwitcher from '../components/SiteSwitcher';
import { useLocation } from 'react-router-dom';

import styles from './CRCLayout.module.scss'

export default function CRCLayout({ children }) {
  const location = useLocation()
  const layoutRef = useRef()
  const winWidth = useWindowSize()
  const { ref, inView } = useInView({
    rootMargin: "-48px",
  })
  const links = [
    {
      key: 0,
      url: 'https://www.bms.com/legal-notice.html',
      text: 'LEGAL NOTICE',
      type: 'external'
    },
    {
      key: 1,
      url: 'https://www.bms.com/privacy-policy.html',
      text: 'PRIVACY POLICY',
      type: 'external'
    },
  ]
  const isiOpacityVariants = {
    initial: {
      opacity: 1
    },
    visible: {
      opacity: 1,
      transition: { ease: 'easeOut' },
    },
    hidden: {
      opacity: 0,
      display: 'none',
      transition: { ease: 'easeOut' },
    }
  }

  const [top, setTop] = useState('isiTop');
  const getTopOffset = useCallback(() => {
    if (window.scrollY < 100) {
      setTop('isiTop')
    }
    if (window.scrollY > 100 && !inView) {
      setTop('isiMinimized')
    }
    if (inView) {
      setTop('isiHidden')
    }
  }, [inView])

  useEffect(() => {
    window.addEventListener("scroll", getTopOffset)
    return () => window.removeEventListener("scroll", getTopOffset)
  }, [getTopOffset])

  if (winWidth <= 1024) {
    return (
      <div className={`${styles.crcLayout} ${top}`} ref={layoutRef}>
        <CRCNavigation layoutRef={layoutRef} />
        {location.pathname === '/c' && (
          <CRCIndication />
        )}
        {children}
        <div ref={ref} className="footer">
          <ISI />
          <Footer id="1914-US-2400490 08/24" links={links} />
        </div>
        {/* {!inView && <StickyISI />} */}
        <motion.div variants={isiOpacityVariants} initial="initial" animate={!inView ? 'visible' : 'hidden'}>
          <StickyISI />
        </motion.div>
      </div>
    )
  }
  return (
    <div className={styles.crcLayout}>
      {location.pathname === '/c' && (
        <CRCIndication />
      )}
      <CRCNavigation />
      <SiteSwitcher />
      {children}
      <div ref={ref} className="footer">
        <ISI />
        <Footer id="1914-US-2400490 08/24" links={links} />
      </div>
      {/* {!inView && <StickyISI />} */}
      <motion.div variants={isiOpacityVariants} initial="initial" animate={!inView ? 'visible' : 'hidden'}>
        <StickyISI />
      </motion.div>
    </div>
  )
}
