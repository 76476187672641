import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { CgChevronRightO } from 'react-icons/cg';
// import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import { Title } from '../pages/Slide3';
import Vimeo from '@u-wave/react-vimeo';
import './video-container.scss';

const videos = [
  // {
  //   id: 836335220,
  //   title: 'Video 1',
  //   legend: (
  //     <span>
  //       KRAZATI for <em>KRAS G12C</em>-Mutated Advanced NSCLC
  //     </span>
  //   ),
  //   thumbnail: '/assets/thumb1.webp',
  //   thumbAlt: 'Thoracic Malignancy Specialist Thumbnail',
  //   src: 'https://player.vimeo.com/video/836335220?muted=0&h=68b7d59851&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  // },
  {
    id: 781866436,
    title: 'Video 2',
    legend: (
      <span>
        Identifying Patients With Possible <em>KRAS G12C</em> Mutations
      </span>
    ),
    thumbnail: '/assets/thumb2.webp',
    thumbAlt: 'Identifying Patients With Possible Mutations Thumbnail',
    src: 'https://player.vimeo.com/video/781866436?muted=0&h=11392a14bd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  },
];

const VideoContainerHome = () => {
  const { isMobile } = useMobileDetect();
  const [currentVideo, setCurrentVideo] = useState(videos[0]);
  const [nextVideoLegend, setNextVideoLegend] = useState(videos[0].legend);

  useEffect(() => {
    const currentIndex = videos.findIndex((obj) => obj === currentVideo);
    if (currentIndex === videos.length - 1) {
      setNextVideoLegend(videos[0].legend);
      return;
    }
    setNextVideoLegend(videos[currentIndex + 1].legend);
  }, [currentVideo]);

  const handleThumbnailClick = (videoID) => {
    setCurrentVideo(videoID);
  };

  function nextVideo() {
    const currentIndex = videos.findIndex((obj) => obj === currentVideo);
    const nextIndex = (currentIndex + 1) % videos.length;
    setCurrentVideo(videos[nextIndex]);
  }

  const handleCookieUpdate = (e) => {
    e.preventDefault()
    window.OneTrust && window.OneTrust.ToggleInfoDisplay()
    document.getElementById("ot-header-id-BG33").click()
  }

  return (
    <>
      {!isMobile && <ContainerMark />}
      <div className='video-container-home'>
        {isMobile && <Title>PEER PERSPECTIVES ON KRAZATI FOR<br /> <em>KRAS G12C</em>-MUTATED ADVANCED NSCLC</Title>}
        <div className='top-container'>
          {/* The line below is the proper way but it's not working with the GA video tracking */}
          {/* <Vimeo id='test' video={currentVideo.id} width='640' height='360' /> */}

          {/* HARDCODED!! Any other way GA wasn't firing the event to track the second video. */}
          {/* If you know a better solution, feel free to fix this and let me know :) */}

          {/* https://my.onetrust.com/s/article/UUID-66bcaaf1-c7ca-5f32-6760-c75a1337c226?language=en_US */}
          {window.OnetrustActiveGroups && window.OnetrustActiveGroups.indexOf('C0004') === -1 ?
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <ConsentRequired>
              <div>Please enable <a href="#" onClick={handleCookieUpdate}>Targeted Advertising Cookies</a> to clear this message and play the video.</div>
            </ConsentRequired> :
            currentVideo.id === 836335220 ? (<Vimeo className='active-video' id={'836335220'} video={836335220} dnt={true} />) :
              (<Vimeo className='active-video' id={'781866436'} video={781866436} dnt={true} />)
          }

          {/* {currentVideo.id === 836335220 && <Vimeo className='active-video' id={'836335220'} video={836335220} dnt={true} />}
          {currentVideo.id === 781866436 && <Vimeo className='active-video' id={'781866436'} video={781866436} dnt={true} />} */}

          <div className='thumbnails'>
            {videos.map((video) => (
              <div key={video.id}>
                <img
                  key={video.id}
                  className={`${currentVideo.id === video.id ? 'active' : ''}`}
                  src={video.thumbnail}
                  alt={video.thumbAlt}
                  onClick={() => handleThumbnailClick(video)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className='bottom-container'>
          <div className='video-title'>Now Playing: {currentVideo.legend}</div>
          {/* <div className='up-next'>
            <span>Up next: {nextVideoLegend}</span>
            <div className='next-button' onClick={() => nextVideo()}>
              <span>Play next</span>
              <CgChevronRightO />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default VideoContainerHome;

const ContainerMark = styled.div`
  position: relative;
  width: 100%;
  max-width: 107.8rem;
`;

const ConsentRequired = styled.div`
  font-family: "Barlow";
  font-size: 16px;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px 48.3%;
  color: #fff;
  a {
    color: #fff;
    text-decoration: underline;
  }
  div {
    position: absolute;
    width: 100%;
  }
  @media (min-width: 1025px) {
    padding: 0 0 48.3%;
  }
`;
