import useMobileDetect from '../../../_hooks/useMobileDetect'
import styled from 'styled-components/macro'
import { device } from '../../_assets/styles/theme'
import Leaving from '../Modal/Leaving'

const Footer = ({ translate = false }) => {
    const { isMobile } = useMobileDetect()

    if (isMobile) {
        return (
            <FooterContainer >
                <FooterMobileWrapper className={`${isMobile ? '__is-mobile' : ''}`}>
                    <FooterMobileLogo>
                        <a href="https://www.bms.com" target="_blank" rel="noreferrer"><img src="/assets/bms-logo.svg" alt="Bristol Myers Squibb Company Logo" /></a>
                    </FooterMobileLogo>

                    <FooterMobileText>
                        <div className="copy">
                            <p style={{ marginBottom: 15 }}>© 2024 Mirati Therapeutics, Inc., <br />a Bristol Myers Squibb company.</p>
                            <p style={{ marginBottom: 15 }}>KRAZATI® and the related logo are registered <br />trademarks of Mirati Therapeutics, Inc.</p>
                            <p>1914-US-2400478 08/24</p>
                        </div>

                        <div className="footer-nav">
                            <nav className="nav">
                                <Leaving className="nav-item" url="https://www.bms.com/legal-notice.html" >Legal Notice</Leaving>
                                <Leaving className="nav-item" url="https://www.bms.com/privacy-policy.html" >Privacy Policy</Leaving>
                                {/* <Leaving className="nav-item" href="https://www.mirati.com/terms-of-use/" target="_blank" rel="noreferrer">Terms of Use</Leaving>
                                <Leaving className="nav-item" href="https://www.bms.com/privacy-policy.html" target="_blank" rel="noreferrer">Privacy Policy</Leaving>
                                <Leaving className="nav-item" href="https://www.mirati.com/contact-us/" target="_blank" rel="noreferrer">Contact Us</Leaving>
                                <Leaving className="nav-item" href="https://www.mirati.com/social-media-community-guidelines/" target="_blank" rel="noreferrer">Community Guidelines</Leaving> */}
                                <a style={{ 'textTransform': 'uppercase' }} href="#" onClick={() => {
                                    window.OneTrust.ToggleInfoDisplay()
                                }}>Your Privacy Choices<img style={{ width: '25px', marginLeft: '4px' }} src="/assets/icons/privacyoptions.svg" alt="" /></a>
                            </nav>
                        </div>

                        <div className="copy" style={{ marginTop: 30 }}>
                            <p><i>This website is intended for U.S. <br />residents 18 years of age or older.</i></p>
                        </div>
                    </FooterMobileText>
                </FooterMobileWrapper>
            </FooterContainer>
        )
    }

    return (
        <FooterContainer id="footer" moveFooter={translate}>
            <FooterDesktopWrapper className={`${isMobile ? '__is-mobile' : ''}`}>
                <LogoWrapper>
                    <a href="https://www.bms.com" target="_blank" rel="noreferrer"><img src="/assets/bms-logo.svg" alt="Bristol Myers Squibb Company Logo" /></a>
                </LogoWrapper>


                <FooterDesktopText>
                    <div className="copy">
                        <p style={{ marginBottom: 15 }}>© 2024 Mirati Therapeutics, Inc., a Bristol Myers Squibb company.</p>
                        <p style={{ marginBottom: 15 }}>KRAZATI® and the related logo are registered trademarks of Mirati Therapeutics, Inc.</p>
                        <p>1914-US-2400478 08/24</p>
                    </div>

                    <div className="footer-nav">
                        <nav className="nav">
                            <Leaving className="nav-item" url="https://www.bms.com/legal-notice.html" >Legal Notice</Leaving>
                            <Leaving className="nav-item" url="https://www.bms.com/privacy-policy.html" >Privacy Policy</Leaving>
                            {/* <Leaving className="nav-item" url="https://www.mirati.com/terms-of-use/" target="_blank" rel="noreferrer">Terms of Use</Leaving>
                            <Leaving className="nav-item" url="https://www.bms.com/privacy-policy.html" target="_blank" rel="noreferrer">Privacy</Leaving>
                            <Leaving className="nav-item" url="https://www.mirati.com/cookie-policy/" target="_blank" rel="noreferrer">Cookies</Leaving> */}
                            {/* <Link to="/n/contact-us" className="nav-item">Contact Us</Link> */}
                            <a style={{ 'textTransform': 'uppercase' }} href="#" onClick={() => {
                                window.OneTrust.ToggleInfoDisplay()
                            }}>Your Privacy Choices<img style={{ width: '25px', marginLeft: '4px' }} src="/assets/icons/privacyoptions.svg" alt="" /></a>
                        </nav>
                        <div className="copy">
                            <p><i>This website is intended for U.S. residents 18 years of age or older.</i></p>
                        </div>
                    </div>

                </FooterDesktopText>
            </FooterDesktopWrapper>
        </FooterContainer>
    )
}

export default Footer

const FooterContainer = styled.div`
`

const FooterDesktopWrapper = styled.div`
    padding: 45px 5.6rem 60px 5.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background: #F8F6FF;
    border-top: 1px solid #f8f6ff;
    margin-top: -1px;
`
const LogoWrapper = styled.div`
    img {
        // width: 17.2rem;
        width: 298px;
    }
`
const FooterDesktopText = styled.div`
    display: flex;
    justify-content: space-between;
    color: #000;
    font-family: Barlow;
    font-size: 1.4rem;
    margin-top: 2.5rem;
    font-weight: 600;

    @media (min-width: 1100px)  {
        flex-direction: column;
    }

    @media (min-width: 1240px)  {
        flex-direction: row;
    }

    .footer-nav {
        img {
            width: 2rem;
        }
        .copy {
            text-align: right;
            padding-right: 2.4rem;
            @media (min-width: 1100px)  {
                text-align: left;
            }
            @media (min-width: 1240px)  {
                text-align: right;
            }
        }
    }

    nav {
        margin-top: 2.4rem;
        margin-bottom: 16px;
        // display: flex;

        a {
            text-transform: uppercase;
            color: #BE2BBB;
            padding: 0 2.4rem;
            border-right: 0.1rem solid #000000;

            &.lowercase {
                text-transform: initial;
            }

            &:hover {
                text-decoration: underline;
            }

            &:first-of-type {
                padding: 0 2.4rem 0 0;
            }

            &:last-of-type {
                border-right: none;
            }
        }
    }
`
const FooterMobileWrapper = styled.div`
    // background: linear-gradient(#0F1282, #3246FF);
    background: #F8F6FF;
    padding: 5.3rem 2.4rem;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

`
const FooterMobileLogo = styled.div`
    margin-bottom: 3.2rem;
    img {
        width: 17.6rem;
        max-width: 251px;
        // width: 15.6rem;
        width: 100%;
    }
`
const FooterMobileText = styled.div`
    color: #000;
    font-family: 'Gilroy', sans-serif;
    font-size: 1.4rem;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;

    nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        a {
            color: #BE2BBB;
            padding: 0 2.4rem;
            border-right: none;
            margin-bottom: .5rem;
            font-size: 1.3rem;
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 400;
            font-family: "Barlow";
            text-decoration: none;
            margin-bottom: 14px;
            font-weight: 600;
        }
    }
    .copy {
        p {
            font-size: 1rem;
            font-weight: 600;
            line-height: 23px;
            font-family: "Barlow";
            padding-left: 0;
        }
    }

    @media ${device.tablet} {
        font-size: 1.6rem;
        nav {
            a {
                font-size: 1.6rem;
            }
        }

        .copy {
            p {
                font-size: 1.2rem;
                font-size: 1.2rem;
            }
        }
    }
`
