import { useEffect, useState} from 'react'
import styled from 'styled-components/macro'
import { device } from '../../_assets/styles/theme'
import useAnimateOnScroll from '../../../_hooks/useAnimateOnScroll'
import { motion, useAnimation } from 'framer-motion'
import IsiContent, { IsiHeader } from './components/IsiContent'
import { rotate } from '../../_assets/styles/animations'
import useMobileDetect from '../../../_hooks/useMobileDetect'
import { FaChevronUp } from 'react-icons/fa'
import { useLocation } from 'react-router-dom';

const ISICollapsing = ({className}) => {
    const { isMobile } = useMobileDetect()
    let location = useLocation();
    const [showingFullIsi, setShowingFullIsi] = useState(false)
    const { inView } = useAnimateOnScroll()
    const control = useAnimation()
    const swiper = document.querySelector('.mySwiper')?.swiper

    const isiEffects = {
        start: { height: '', overflowY: 'initial', transition: {duration: .5,} },
        end: {height: '100%', overflowY: 'scroll', transition: {duration: .5}},
        fadeIn: {opacity: 1,  height: '', transition: {duration:.3}},
        fadeOut: {opacity: 0, height: 0, transition: {duration:.3}}
    }

    useEffect(() => {
        if (showingFullIsi) {
            control.start("end");
            document.body.style.overflow = "hidden"
        }

        if (!showingFullIsi) {
            control.start("start");
            document.body.style.overflow = "initial"
        }
    }, [showingFullIsi, control]);

    useEffect(()=> {
        if (inView) {
            control.start("fadeOut");
        }

        if (!inView) {
            control.start("fadeIn");
        }

    }, [inView, control])

    const handleClick = () => {
		const truncateElement = document.querySelector('body');
		truncateElement.classList.toggle("isi-expanded");

        if (inView) {
            return
        }

        if (showingFullIsi) {
            if(window.location.hash === '#important-safety-information') {
              window.location.hash = ''
            }
            setShowingFullIsi(false)
            return
        }

        setShowingFullIsi(true)
    }

    useEffect(()=> {
        if (!swiper) return
        if(window.location.hash === '#important-safety-information') {
          setShowingFullIsi(true)
        } else {
            setShowingFullIsi(false)
        }
      }, [swiper, location.hash])

    useEffect(()=> {
      const states = ['visible', 'collapse'];
      document.body.classList.remove('isi-visible');
      document.body.classList.remove('isi-collapse');
      document.body.classList.remove('isi-fixed');
      if (states.includes(className)) {
        document.body.classList.add("isi-" + className);
      } else {
        document.body.classList.add("isi-fixed");
      }
    }, [className]);

    return (
      <ISIWrapper
          className={`swiper-no-swiping swiper-pointer-events ${className}`}
          animate={control}
          variants={isiEffects}
          initial="start"
          style={showingFullIsi ? {zIndex: 300, position: 'fixed'} : {zIndex: 10} }
      >
      <HeaderWrapper onClick={handleClick}>
        <HeaderTitle>
          <div>Important Safety Information</div>
        </HeaderTitle>
        <HeaderTitle>
          <div>Indication</div>
        </HeaderTitle>
        <MotionImg animate={control} variants={rotate}  initial="start" className="custom-icon" >
            <CustomFaChevronUp  />
        </MotionImg>
      </HeaderWrapper>

    {isMobile && <IsiHeader />}

      {!isMobile && <Content className={className}>
        {showingFullIsi ? <IsiContent /> : <IsiHeader />}
      </Content>}
      </ISIWrapper>
    )
}

export default ISICollapsing


// Styles
export const ISIWrapper = styled(motion.div)`
    background: ${({theme})=> theme.colors.white};
    width: 100%;
    z-index: 10;
    bottom: 0;
    padding-right: 1rem;
    transition: opacity 0.3s;

    &.floatingWithIsi {
        position: absolute;
    }

    &.hidden {
        opacity: 0 !important;
        pointer-events: none;
    }


    &::-webkit-scrollbar {
        width: .5rem;
        background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #333;
    }


    section {
        margin: .5rem 5vw 2.4rem;
        max-width: 128rem;

        a {
            text-decoration: underline;
            color: ${({theme})=> theme.colors.blue}
        }
    }

    @media ${device.mobile} {
        padding: .5rem 5vw 1rem;
        section {
            margin: 0;
        }
    }

    @media ${device.tablet} {
        section {
            margin: 1.5rem 4rem 2rem;
        }
    }

    @media ${device.desktop} {
      padding: 0 5.6rem;
      position: fixed;
      bottom: 0;

      section {
          margin: 0 auto;
          padding: 1.6rem 1.6rem 0;
      }
    }

`
const CustomFaChevronUp = styled(FaChevronUp)`
    font-size: 2.6rem;
    color: #3246FF;

`
const MotionImg = styled(motion.div)`
    cursor: pointer;
    margin-right: 0.8rem;

    .custom-icon {
        transform: 0;
    }
`
export const MotionImage = styled(motion.img)`
    cursor: pointer;
`
export const HeaderWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
        width: 1.6rem;
        height: 1.6rem;
    }
    .custom-icon {
      position: absolute;
      right: 0;
    }

    @media ${device.tablet} {
        margin-bottom: 0px;

        img {
            width: 2rem;
            height: 2rem;
        }
    }

    @media ${device.desktop} {
        padding: 0;
        height: 4rem;

        &.collapse {
            max-height: 4rem;
        }
    }

`
export const HeaderTitle = styled.h2`
    color: ${({theme})=> theme.colors.blue};
    font-family: "Barlow", sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0..5rem;
    @media ${device.tablet} {
        font-size: 1.6rem;
    }

    @media ${device.desktop} {
      font-size: 1.9rem;
      width: 50%;
    }

`

const Content = styled.div`
  max-height: 8.1rem;

  &.visible {
    max-height: 8.1rem;
    transition: max-height .25s ease;
  }

  &.collapse {
    max-height: 0;
    transition: max-height .25s ease;
    animation: fadeout .25s ease;
  }

  @media ${device.tablet} {
    max-height: 12.1rem;

    &.visible {
        max-height: 12.1rem;
    }
  }

  @media ${device.laptop} {

  }
`
