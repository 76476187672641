import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import React from "react";
import styled from "styled-components/macro";
import { device } from "../../../_assets/styles/theme";
import Leaving from "../../Modal/Leaving";
import Footer from "../../Footer";

/* Contains ISI body text */

export const IsiHeader = ({ hiddenRef }) => {
  return (
    <ContentWrapper ref={hiddenRef}>
      <ISIWrap>
        <ISIHalf>
          <ContentCustomSpace>WARNINGS AND PRECAUTIONS</ContentCustomSpace>

          <ContentCustomSpace>Gastrointestinal Adverse Reactions</ContentCustomSpace>
          <p>
            <span className="bullet">•</span>KRAZATI can cause severe gastrointestinal adverse reactions<br />
            <span className="bullet">•</span>Monitor and manage patients using supportive care, including antidiarrheals, antiemetics, or fluid replacement, as indicated. Withhold, reduce the dose, or permanently discontinue KRAZATI based on severity
          </p>
        </ISIHalf>
        <ISIHalf className="indication-container">
          <p className="indication">
            KRAZATI is indicated for the treatment of adult patients with
            <em> KRAS G12C</em>-mutated locally advanced or metastatic non-small cell
            lung cancer (NSCLC), as determined by an FDA-approved test, who have
            received at least one prior systemic therapy.
          </p>

          <p className="indication">
            This indication is approved under accelerated approval based on objective
            response rate (ORR) and duration of response (DOR). Continued approval for
            this indication may be contingent upon verification and description of a
            clinical benefit in a confirmatory trial(s).
          </p>
        </ISIHalf>
      </ISIWrap>
    </ContentWrapper>
  );
};

// export const IsiHeaderMobile = () => {
//   return (
//     <ContentWrapperMobile>
//       <ContentCustomSpace>
//         <strong>WARNINGS AND PRECAUTIONS</strong>
//       </ContentCustomSpace>

//       <ContentCustomSpace>
//         <strong>Gastrointestinal Adverse Reactions</strong>
//       </ContentCustomSpace>
//       <p className="close-bullet">
//         <span className="bullet">•</span>KRAZATI can cause severe gastrointestinal adverse reactions<br />
//         <span className="bullet">•</span>Monitor and manage patients using supportive care, including antidiarrheals, antiemetics, or fluid replacement, as indicated. Withhold, reduce the dose, or permanently discontinue KRAZATI based on severity
//       </p>
//     </ContentWrapperMobile>
//   );
// };

const IsiContent = ({ hiddenRef }) => {
  const [currentLocation, setCurrentLocation] = useState('');
  let location = useLocation();
  useEffect(() => {
    setCurrentLocation(location.pathname.split('/')[2]);
  }, [location])
  return (
    <ContentWrapper>
      <ISIWrap>
        <ISIHalf>
          <strong className="warning p-top">WARNINGS AND PRECAUTIONS</strong>
          <strong>Gastrointestinal Adverse Reactions</strong>
          <p className="close-bullet">
            <span className="bullet">•</span>KRAZATI can cause severe gastrointestinal adverse reactions<br />
            <span className="bullet">•</span>Monitor and manage patients using supportive care, including antidiarrheals, antiemetics, or fluid replacement, as indicated. Withhold, reduce the dose, or permanently discontinue KRAZATI based on severity
          </p>
          <strong>QTc Interval Prolongation</strong>
          <p>
            <span className="bullet">•</span>KRAZATI can cause QTc interval prolongation, which can increase the risk for ventricular tachyarrhythmias (eg, torsades de pointes) or sudden death
          </p>
          <p>
            <span className="bullet">•</span>Avoid concomitant use of KRAZATI with other products with a known potential to prolong the QTc interval. Avoid use of KRAZATI in patients with congenital long QT syndrome and in patients with concurrent QTc prolongation
          </p>

          <p>
            <span className="bullet">•</span>Monitor ECGs and electrolytes prior to starting KRAZATI, during concomitant use, and as clinically indicated in patients with congestive heart failure, bradyarrhythmias, electrolyte abnormalities, and in patients who are taking medications that are known to prolong the QT interval. Withhold, reduce the dose, or permanently discontinue KRAZATI, depending on severity
          </p>

          <strong>Hepatotoxicity</strong>
          <p>
            <span className="bullet">•</span>KRAZATI can cause hepatotoxicity, which may lead to drug-induced liver injury and hepatitis
          </p>
          <p>
            <span className="bullet">•</span>Monitor liver laboratory tests (AST, ALT, alkaline phosphatase, and total bilirubin) prior to the start of KRAZATI, and monthly for 3 months or as clinically indicated, with more frequent testing in patients who develop transaminase elevations. Reduce the dose, withhold, or permanently discontinue KRAZATI based on severity
          </p>

          <strong>Interstitial Lung Disease/Pneumonitis</strong>
          <p>
            <span className="bullet">•</span>KRAZATI can cause interstitial lung disease (ILD)/pneumonitis, which can be fatal
          </p>

          <p>
            <span className="bullet">•</span>Monitor patients for new or worsening respiratory symptoms indicative of ILD/pneumonitis (eg, dyspnea, cough, fever) during treatment with KRAZATI
          </p>

          <p>
            <span className="bullet">•</span>Withhold KRAZATI in patients with suspected ILD/pneumonitis and permanently discontinue KRAZATI if no other potential causes of ILD/pneumonitis are identified
          </p>

          <strong>ADVERSE REACTIONS</strong>
          <p>
            <span className="bullet">•</span>The most common adverse reactions in NSCLC patients (≥20%) are diarrhea, nausea, fatigue, vomiting, musculoskeletal pain, hepatotoxicity, renal impairment, dyspnea, edema, decreased appetite, cough, pneumonia, dizziness, constipation, abdominal pain, and QTc interval prolongation
          </p>

          <strong>USE IN SPECIFIC POPULATIONS</strong>
          <strong>Females and Males of Reproductive Potential</strong>
          <p>
            <span className="bullet">•</span>Infertility: Based on findings from animal studies, KRAZATI may impair fertility in females and males of reproductive potential
          </p>

          <strong>Please see <Leaving url="https://packageinserts.bms.com/pi/pi_krazati.pdf" target="_blank">Full Prescribing Information</Leaving>.</strong>
          <MobileFooterWrapper className={`${currentLocation}`}>
            <Footer />
          </MobileFooterWrapper>
        </ISIHalf>
        <ISIHalf className="indication-container">
          <p className="indication">
            KRAZATI is indicated for the treatment of adult patients with <i>KRAS G12C</i>-mutated locally advanced or metastatic non-small cell lung cancer (NSCLC), as determined by an FDA-approved test, who have received at least one prior systemic therapy.
          </p>

          <p className="indication">
            This indication is approved under accelerated approval based on objective response rate (ORR) and duration of response (DOR).  Continued approval for this indication may be contingent upon verification and description of a clinical benefit in a confirmatory trial(s).
          </p>
        </ISIHalf>
      </ISIWrap>
    </ContentWrapper >
  );
};

export default IsiContent;

const MobileFooterWrapper = styled.div`
  display: block;
  margin-top: 30px;
  margin-left: -3vw;
  margin-right: -3vw;
  &.references {
    margin-left: -5vw;
    margin-right: -5vw;
  }
  @media ${device.desktop} {
    display: none;
  }
`

const ContentWrapper = styled.div`
  font-family: "Barlow", sans-serif;
  color: #434343;

  .bullet {
    position: absolute;
    left: 1rem;
  }

  p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    font-weight: 500;
    position: relative;
    padding-left: 2rem;

    &.indication {
      padding-left: 0;

      &:nth-of-type(2) {
        margin-bottom: 2rem;
      }
    }
    &:last-of-type {
      padding-bottom: 1rem;
    }

    &.close-bullet {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  a {
    text-decoration: underline;
    color: inherit;
  }

  @media ${device.tablet} {
    p {
      font-size: 1.6rem;
      margin: 0.5rem 0;
      padding-right: 1.6rem;
    }
  }

  @media ${device.desktop} {
    p {
      padding-left: 3rem;
    }
  }
  strong {
  	color: #434343;
  	margin-bottom: 0.5rem;
  	margin-top: 0.5rem;
  	font-size: 0.9rem;
  	display: block;
    &.warning {
      margin: 0;
      + strong {
        margin-top: 5px;
      }
    }
    &.p-top {
      margin-top: 6px;
    }
  	@media ${device.tablet} {
  		margin-top: 2.5rem;
  		margin-bottom: 0.8rem;
  		font-size: 1.6rem;
  	}
  }
`;
const ISIWrap = styled.div`
  display: flex;
`;
const ISIHalf = styled.div`
  flex: 1 0 50%;
  &.indication-container {
    display: none;
  }
  @media ${device.desktop} {
    &.indication-container {
      display: inherit;
      flex-direction: column;
    }
  }
`;

const ContentWrapperMobile = styled(ContentWrapper)`
  padding-right: 0;

  p {
    margin: 0;
    font-size: 1rem;
  }
`;

const ContentCustomSpace = styled.h3`
  color: #434343;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: 700;

  @media ${device.tablet} {
	margin-bottom: 0.8rem;
    font-size: 1.6rem;
  }

  &.prescribing {
    padding-bottom: 2rem;
  }

`;
