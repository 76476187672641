import { useState } from 'react'
import { motion } from 'framer-motion'
import useWindowSize from '../../../_hooks/useWindowSize'

import { Link, useLocation } from 'react-router-dom'
import CRCSubMenu from './CRCSubMenu'
import CRCPrimaryMenu from './CRCPrimaryMenu'

import styles from "./CRCNavigation.module.scss"

export default function CRCNavigation({ layoutRef }) {
  const location = useLocation()
  const winWidth = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)

  const mobileVariants = {
    initial: {
      height: '0px',
      overflow: 'hidden'
    },
    visible: {
      height: '100vh',
      transition: { ease: 'easeOut' }
    },
    hidden: {
      height: 0,
      transition: { ease: 'easeOut' }
    }
  }

  const toggleMobileMenu = () => {
    if (isOpen) {
      document.querySelector('html').style.overflow = 'visible';
    } else {
      document.querySelector('html').style.overflow = 'hidden';
    }
    setIsOpen(!isOpen)
  }

  const setSiteSwitchPath = () => {
    let loc = location.pathname.split('/')[2]
    if (!loc) {
      return '/n'
    }
    if (loc === "testing-for-kras-g12c") {
      return '/n/testing'
    }
    if (loc === 'dosage') {
      return '/n/dosing'
    }
    return '/n/' + loc
  }

  if (winWidth <= 1024) {
    return (
      <div className={`${styles.mobileNavigation} ${isOpen ? `${styles.mobileOpen}` : ''}`}>
        <header>
          <div className={styles.mobileLogo}>
            <Link to="/c"><img className={!isOpen ? `${styles.hideLogo}` : ''} src="/assets/logo/logo-landing-page.png" alt="KRAZATI - adagrasib 200 mg Tablets Logo" /></Link>
            <Link to="/c"><img className={isOpen ? `${styles.hideLogo}` : ''} src="/assets/logo/logo-landing-page-invert.png" alt="KRAZATI - adagrasib 200 mg Tablets Logo" /></Link>
          </div>

          <div className={!isOpen ? `${styles.hideLogo}` : `${styles.mobileSwitchIndication}`}>
            <span>Switch Indications</span>
            <div className={styles.mobileSwitchToggle}>
              <a className={styles.mobileSwitchToggleActive} href="/#">CRC</a>
              <a href={setSiteSwitchPath()}>NSCLC</a>
            </div>
          </div>

          <div className={styles.mobileCRCBadge}>
            <img className={isOpen ? `${styles.hideLogo}` : ''} src="/assets/icons/icon-crc-badge-nav.svg" alt="Disease Indication Flag" />
            <img className={!isOpen ? `${styles.hideLogo}` : ''} src="/assets/icons/icon-crc-badge-nav-open.svg" alt="Disease Indication Flag" />
          </div>

          <div className={`${styles.menuToggle} ${styles.mobileToggleOpen}`} onClick={() => {
            toggleMobileMenu();
          }}>
            <img className={isOpen ? `${styles.hideLogo}` : ''} src="/assets/icons/icon-menu-toggle.svg" alt="Open mobile menu" />
            <img className={!isOpen ? `${styles.hideLogo}` : `${styles.mobileCloseIcon}`} src="/assets/icons/icon-mobile-close.svg" alt="Close mobile menu" />
          </div>

          <motion.div className={styles.mobileLinks} variants={mobileVariants} initial="initial" animate={isOpen ? "visible" : "hidden"}>
            <div className={styles.mobilePrimaryLinks}>
              <CRCSubMenu title="<i>KRAS G12C</i> in CRC" links={[
                {
                  url: '/c/challenging-mutation',
                  text: 'About <i>KRAS G12C</i>'
                },
                {
                  url: '/c/testing',
                  text: 'Testing for <i>KRAS G12C</i>'
                }
              ]} />
              <CRCSubMenu title="Clinical Data" links={[
                {
                  url: '/c/study-design',
                  text: 'Study Design'
                },
                {
                  url: '/c/efficacy',
                  text: 'Efficacy'
                },
                {
                  url: '/c/safety',
                  text: 'Safety'
                }
              ]} />
              <Link to="/c/dosage">Dosing</Link>
              <CRCSubMenu title="Resources & Support" links={[
                {
                  url: '/c/resources',
                  text: 'Resources'
                },
                {
                  url: '/c/support',
                  text: 'Mirati & Me'
                }
              ]} />
            </div>
            <div className={`${styles.mobileSecondaryLinks} mobileSecondayLinks`}>
              <a href={`${location.pathname}#important-safety-information`} target="_self">Important Safety Information</a>
              <a href="https://packageinserts.bms.com/pi/pi_krazati.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>
              <Link to="/c/references">References</Link>
              <a href="https://www.krazati.com/c" target="_blank" rel="noreferrer">Visit Patient Site</a>
            </div>
          </motion.div>
        </header>
      </div>
    )
  }
  return (
    <div className={styles.navigation}>
      <div className={styles.navigationWrapper}>
        <header>
          <div className={styles.logo}>
            <Link to="/c"><img src="/assets/logo/logo-landing-page-invert.svg" alt="KRAZATI - adagrasib 200 mg Tablets Logo" /></Link>
          </div>
          <div className={styles.mainLinks}>
            <CRCPrimaryMenu />
          </div>
          <div className={styles.links}>
            <a href={`${location.pathname}#important-safety-information`} target="_self">Important Safety Information</a>
            <a href="https://packageinserts.bms.com/pi/pi_krazati.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>
            <Link to="/c/references">References</Link>
            <a href="https://www.krazati.com/c" target="_blank" rel="noreferrer">Visit Patient Site</a>
          </div>
        </header>
      </div>
    </div>
  )
}
