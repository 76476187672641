import CRCLayout from "./layouts/CRCLayout";
import InteriorBanner from "./components/InteriorBanner"
import MainContent from "../_common/components/Layout/MainContent"
import Container from "../_common/components/Layout/Container"
import MetaTags from "../_common/components/MetaTags";
import styles from './CRCSupport.module.scss'

export default function CRCSupport() {
  return (
    <CRCLayout>
      <MetaTags
        title='Patient Support Resources'
        description="Explore support programs and resources available to patients prescribed Krazati®."
        ogUrl='https://www.krazatihcp.com/c/support'
      />
      {/* Banner */}
      <InteriorBanner title="MIRATI & ME" imageUrl="/banners/banner-mm.jpg" desktopImageUrl="/banners/banner-mm-desktop.jpg" />

      <MainContent>

        <div className={styles.support}>
          <Container>
            <h2 className={styles.pageHeading}>Explore patient support services designed to help you guide your patients throughout their treatment journey</h2>
            <p>Since FDA approval, the average time to first fill for KRAZATI has been less than 6 business days through our patient support program with help from our specialty pharmacy partners.</p>
            <img className={styles.imgLogoDesktop} src="/assets/support/img-logo.jpg" alt="Mirati and Me Logo" />
            <img className={styles.imgLogoMobile} src="/assets/support/img-logo-mobile.jpg" alt="Mirati and Me Logo" />

            <div className={styles.supportCards}>
              <div>
                <img className={`${styles.icon} ${styles.iconCheckmark}`} src="/assets/support/icon-checkmark.svg" alt="Mirati Coverage And Access Support" />
              </div>
              <div>
                <h3>Coverage and access support</h3>
                <p><strong>Benefits Verification</strong></p>
                <ul>
                  <li>Verify patients' insurance coverage and out-of-pocket expenses for prescribed Mirati medications</li>
                  <li>Determine if steps are required to obtain prescription approval, such as prior authorization</li>
                  <li>Assist with identification of insurers' requirements for appealing a denied claim</li>
                  <li>Identify in-network specialty pharmacy options</li>
                </ul>
              </div>
            </div>
            <div className={styles.supportCards}>
              <div>
                <img className={`${styles.icon} ${styles.iconPeople}`} src="/assets/support/icon-people.svg" alt="Mirati Financial Support" />
              </div>
              <div>
                <h3>Financial support*</h3>
                <p><strong>Savings Card Assistance</strong></p>
                <ul>
                  <li>May provide eligible patients with assistance for paying deductibles, co-pays, and/or co-insurance for prescribed Mirati medications</li>
                  <li>Eligible commercially insured patients may pay as little as $0</li>
                  <li>Determine patients' eligibility and activate a savings card</li>
                </ul>
                <p><strong>Free Product Assistance</strong></p>
                <ul>
                  <li>May provide assistance in the form of free product for eligible patients who do not have insurance coverage or are underinsured<sup>†</sup></li>
                </ul>
                <p><strong>Free Trial Offer</strong></p>
                <ul>
                  <li>May provide a free 30-day supply of prescribed Mirati medications to help determine if therapy is right for your patients<sup>‡</sup></li>
                  <li>Determine patients' eligibility for a <b><a href="https://www.miratiandme.com/freetrial/" target="_blank" rel="noreferrer">free trial offer</a></b></li>
                </ul>
                <p><strong>Quick Start and Bridge Supply</strong></p>
                <ul>
                  <li>May provide assistance in the form of free product for eligible patients who experience a delay in reauthorization or a change in insurance coverage</li>
                </ul>
              </div>
            </div>
            <div className={styles.supportCards}>
              <div>
                <img className={`${styles.icon} ${styles.iconLightbulb}`} src="/assets/support/icon-lightbulb.svg" alt="Mirati Educational Support" />
              </div>
              <div>
                <h3>Educational support</h3>
                <p><strong>Product and Disease Education for Patients and Caregivers</strong></p>
                <ul>
                  <li>Personalized education about prescribed Mirati medications, coverage, and eligibility for programs</li>
                </ul>
              </div>
            </div>
            <div className={styles.supportCards}>
              <div>
                <img className={`${styles.icon} ${styles.iconHands}`} src="/assets/support/icon-hands.svg" alt="Mirati Emotional Support" />
              </div>
              <div>
                <h3>Emotional support</h3>
                <p><strong>Support Groups and Helpline for Patients and Caregivers</strong></p>
                <ul>
                  <li>Independent organizations, as well as local and online support groups, may provide counseling and emotional assistance</li>
                </ul>
              </div>
            </div>
            <div className={styles.supportCards}>
              <div>
                <img className={`${styles.icon} ${styles.iconGene}`} src="/assets/support/icon-gene.svg" alt="Mirati Additional Support" />
              </div>
              <div>
                <h3>Additional support</h3>
                <p><strong>Independent Advocacy and Charitable Organizations</strong></p>
                <ul>
                  <li>Independent organizations may be available to help patients and their caregivers with co-pay assistance,<sup>§</sup> as well as educational and emotional support</li>
                </ul>
              </div>
            </div>

            <p><small>*Restrictions and conditions apply.</small></p>
            <p><small><sup>†</sup>Annual family gross income equal to or less than $150,000 for household size of 2 people; $25,000 per additional person in household. Additional conditions and restrictions apply.</small></p>
            <p><small><sup>‡</sup>One-time offer per patient (per lifetime). Certain eligibility criteria and restrictions apply.</small></p>
            <p><small><sup>§</sup>Individual organizations determine patient eligibility.</small></p>

            <div className={styles.calloutWrapper}>
              <h2 className={styles.callout}>To learn about these programs and more, call <a href="tel:18446472842">1-844-647-2842</a> (Option 1).</h2>
              <a className={styles.calloutButton} href="https://www.miratiandme.com/hcp" target="_blank" rel="noreferrer">Visit MiratiandMe.com</a>
            </div>
          </Container>
        </div>

      </MainContent >
    </CRCLayout >
  )
}
