import Container from "../../_common/components/Layout/Container";
import useWindowSize from '../../_hooks/useWindowSize';
import styles from './InteriorBanner.module.scss'
export default function InteriorBanner({ children, imageUrl, desktopImageUrl, title, cn }) {
  const winWidth = useWindowSize();
  let img = '';
  if (winWidth <= 1024) {
    img = imageUrl
  } else {
    img = desktopImageUrl
  }
  return (
    <div className={`${styles.interiorBanner} ${cn && cn}`} style={{ 'backgroundImage': `url(` + img + `)` }}>
      <Container>
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      </Container>
    </div >
  )
}
