import React, { useEffect } from 'react'
import { HeaderTitle, MotionImage } from '../Collapsing'
import IsiContent, { IsiHeader } from './IsiContent'
import { rotate } from '../../../_assets/styles/animations'
import styled from 'styled-components/macro'
import { device } from '../../../_assets/styles/theme'
import useMobileDetect from '../../../../_hooks/useMobileDetect';
import { useState } from 'react'
import useAnimateOnScroll from '../../../../_hooks/useAnimateOnScroll'
import { motion, useAnimation } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import FloatingIndication from './FloatingIndication';

const FloatingIsi = ({ className }) => {
  const [showingFullIsi, setShowingFullIsi] = useState(false)
  const { inView } = useAnimateOnScroll()
  const control = useAnimation()
  const swiper = document.querySelector('.mySwiper')?.swiper
  const location = useLocation();
  const isiEffects = {
    start: { height: '', overflowY: 'initial', transition: { duration: .5, } },
    end: { height: '100%', overflowY: 'scroll', transition: { duration: .5 } },
    fadeIn: { opacity: 1, height: '', transition: { duration: .3 } },
    fadeOut: { opacity: 0, height: 0, transition: { duration: .3 } }
  }
  const { isMobile } = useMobileDetect();

  useEffect(() => {
    if (showingFullIsi) {
      control.start("end");
      document.body.style.overflow = "hidden"
    }

    if (!showingFullIsi) {
      control.start("start");
      document.body.style.overflow = "initial"
    }
  }, [showingFullIsi, control]);

  useEffect(() => {
    if (inView) {
      control.start("fadeOut");
    }

    if (!inView) {
      control.start("fadeIn");
    }

  }, [inView, control])

  const handleClick = () => {
    if (inView) {
      return
    }

    if (showingFullIsi) {
      if (window.location.hash === '#important-safety-information') {
        window.location.hash = ''
      }
      setShowingFullIsi(false)
      return
    }

    setShowingFullIsi(true)
  }

  useEffect(() => {
    if (!swiper) return

    if (window.location.hash === '#important-safety-information') {
      setShowingFullIsi(true)
    } else {
      setShowingFullIsi(false)
    }
  }, [swiper, location.hash])

  if (isMobile) {
    return (
      <ISIWrapper
        id='floating-isi'
        className={`swiper-no-swiping swiper-pointer-events ${className}`}
        animate={control}
        variants={isiEffects}
        initial="start"
        style={showingFullIsi ? { zIndex: 300, position: 'fixed' } : null}
      >
        <FloatingIndication />
        <HeaderWrapper onClick={handleClick}>
          <HeaderTitle>IMPORTANT SAFETY INFORMATION</HeaderTitle>
          <MotionImage animate={control} variants={rotate} initial="start" className="custom-icon down" src="/assets/icons/arrow_chevron_up.svg" alt="Up navigation arrow" />
        </HeaderWrapper>
        <Content className={className}>
          {!showingFullIsi && (
            <IsiHeader />
          )}

          {showingFullIsi && <IsiContent />}
        </Content>
      </ISIWrapper>
    )
  } else {
    return (
      <ISIWrapper
        id='floating-isi'
        className={`swiper-no-swiping swiper-pointer-events ${className}`}
        animate={control}
        variants={isiEffects}
        initial="start"
        style={showingFullIsi ? { zIndex: 300, position: 'fixed' } : null}
      >
        <HeaderWrapper onClick={handleClick}>
          <HeaderTitle>INDICATION AND IMPORTANT SAFETY INFORMATION</HeaderTitle>
          <MotionImage animate={control} variants={rotate} initial="start" className="custom-icon down" src="/assets/icons/arrow_chevron_up.svg" alt="Up navigation arrow" />
        </HeaderWrapper>
        <Content className={className}>
          {!showingFullIsi && (
            <IsiHeader />
          )}

          {showingFullIsi && <IsiContent />}
        </Content>
      </ISIWrapper>
    )
  }
}

export default FloatingIsi

const ISIWrapper = styled(motion.div)`
  background: white;
  padding: 0 3vw;
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;

  &.floatingWithIsi {
    position: absolute;
  }

  &::-webkit-scrollbar {
      width: .5rem;
      background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
      background: #333;
  }


  section {
    margin: .5rem 5vw 2.4rem;
    max-width: 128rem;

    a {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.blue}
    }
  }

  @media ${device.tablet} {
    padding: 0 3vw;
      section {
          margin: 1.5rem 4rem 2rem;
      }
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 2.2rem;
  img {
      width: 1.6rem;
      height: 1.6rem;
  }

  @media ${device.tablet} {
      height: 3.5rem;
      margin-bottom: 0px;
      img {
          width: 2rem;
          height: 2rem;
      }
  }
`

const Content = styled.div`
  max-height: 5rem;
  margin-bottom: .5rem;
  transition: max-height 0.5s ease;
  &.visible {
    max-height: 6.5rem;
  }

  &.collapse {
    max-height: 0;
    transition: max-height .1s ease;
    animation: fadeout .1s ease;
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    max-height: 11.1rem;

    &.visible {
      max-height: 11.1rem;
    }
  }
`
