import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseLink, BaseText, Box, Container, FlexCentered, StyledBothSideShape, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect'
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import List from '../../_components/List';
import AccordionNoArrow from '../../_components/Accordion/AccordionNoArrow';
import HorizontalAccordion from '../../_components/Accordion/HorizontalAccordion';
import { FaChevronRight } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
import Leaving from '../../_components/Modal/Leaving';

const FinancialContent = () => {
  const { isMobile } = useMobileDetect()
  return (
    <Content direction="column" className={!isMobile ? "financial" : ''}>
      <div>
        <GreenText>Savings Card Assistance</GreenText>
        <List display="flex" textColor={'#3A3A3A'} weight="600" bulletColor="#3A3A3A" texts={[
          'May provide eligible patients with assistance for paying deductibles, co-pays, and/or co-insurance for prescribed Mirati medications',
          'Eligible commercially insured patients may pay as little as $0',
          <>Determine patients’ eligibility and activate a <Leaving url="https://krazatisavingscard.com/" target="_blank" rel="noreferrer"><u className="link-out">savings&nbsp;card</u></Leaving></>
        ]} />
        <GreenText>Free Product Assistance</GreenText>
        <List display="flex" textColor={'#3A3A3A'} weight="600" bulletColor="#3A3A3A" texts={[
          <span>May provide assistance in the form of free product for eligible patients who do not have insurance coverage or are underinsured<sup>†</sup></span>
        ]} />
        <GreenText>Free Trial Offer</GreenText>
        <List display="flex" textColor={'#3A3A3A'} weight="600" bulletColor="#3A3A3A" texts={[
          <>May provide a free 30-day supply of prescribed Mirati medications to help determine if therapy is right for your patients<sup>‡</sup></>,
          <>Determine patients’ eligibility for a <Leaving url="https://miratiandme.com/freetrial/" target="_blank" rel="noreferrer"><u className="link-out">free trial offer</u></Leaving></>
        ]} />
        <GreenText>Quick Start and Bridge Supply</GreenText>
        <List display="flex" textColor={'#3A3A3A'} weight="600" bulletColor="#3A3A3A" texts={[
          'May provide assistance in the form of free product for eligible patients who experience a delay in re-authorization or a change in insurance coverage'
        ]} />
      </div>

      <Refs>
        <p style={{ marginBottom: 10 }}><span>*</span>Restrictions and conditions apply.</p>
        <p><sup>&dagger;</sup>Annual family gross income equal to or less than $150,000 for household size of 2 people; $25,000 per additional person in household. Additional conditions and restrictions apply.</p>
        <p><sup>&#x2021;</sup>One-time offer per patient (per lifetime). Certain eligibility criteria and restrictions apply.</p>
      </Refs>
    </Content>
  )
}

const CoverageContent = () => {
  const { isMobile } = useMobileDetect()

  return (
    <Content direction="column" className={!isMobile ? "coverage" : ''}>
      <div>
        <GreenText>Benefits Verification</GreenText>
        <List display="flex" textColor={'#3A3A3A'} weight="600" bulletColor="#3A3A3A" texts={[
          'Verify patients’ insurance coverage and out-of-pocket expenses for prescribed Mirati medications',
          'Determine if steps are required to obtain product approval, such as prior authorization',
          'Assist with identification of insurers’ requirements for appealing a denied claim',
          'Identify in-network specialty pharmacy options'
        ]} />
      </div>
      {!isMobile && <Refs>*Restrictions and conditions apply.</Refs>}
    </Content>
  )
}

const EmotionalContent = () => {
  const { isMobile } = useMobileDetect()

  return (
    <Content direction="column" className={!isMobile ? "emotional" : ''}>
      <div>
        <GreenText>Support Groups and Helpline for Patients and Caregivers</GreenText>
        <ul>
          <li className="custom-text">Independent organizations, as well as local and online support groups, may provide counseling and emotional assistance</li>
        </ul>
      </div>
      <Refs>*Restrictions and conditions apply.</Refs>
    </Content>
  )
}

const EducationalContent = () => {
  const { isMobile } = useMobileDetect()

  return (
    <Content direction="column" className={!isMobile ? "educational" : ''}>
      <div>
        <GreenText>Product and Disease Education for Patients and Caregivers</GreenText>
        <ul>
          <li className="custom-text">Personalized education about prescribed Mirati medications, coverage, and eligibility for programs</li>
        </ul>
      </div>
      {!isMobile && <Refs>*Restrictions and conditions apply.</Refs>}
    </Content>
  )
}

const AdditionalContent = () => {
  const { isMobile } = useMobileDetect()

  return (
    <Content direction="column" className={!isMobile ? "additional" : ''} >
      <div>
        <GreenText>Independent Advocacy and Charitable Organizations </GreenText>
        <ul>
          <li className="custom-text">Independent organizations may be available to help patients and their caregivers with co-pay assistance,<sup>§</sup> as well as educational and emotional support</li>
        </ul>
      </div>

      <Refs>
        {!isMobile && <p><span>*</span>Restrictions and conditions apply.</p>}
        <p><sup>&sect;</sup>Individual organizations determine patient eligibility.</p>
      </Refs>
    </Content>
  )
}

const Slide1 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const swiper = document.querySelector('.mySwiper');
  const [recalculate, setRecalculate] = useState(false)
  const [showPageContent, setShowPageContent] = useState(false)
  const slideOverlay = `radial-gradient(circle, #F3EAF6 0%, #E1D9DD 27.15%, #CCBEC7 59.94%, #A09AAF 100%)`

  useActiveLink(setShowHeaderSecondaryMenu, 'support')

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setShowPageContent(true)
      setCurrentSlide(1)
      setOverlay(slideOverlay)
      swiper.swiper.disable();
      return
    }
    setShowPageContent(false)
  }, [swiperSlide.isActive])

  if (isMobile) {
    return (
      <CustomContainer className={showPageContent ? 'visible' : 'hidden'} id="support-container" isiconfig={isiconfig}>
        <Scroll>
          <ContainerScroll recalculate={recalculate} >
            <CustomWrapper >
              <ImageWrapper>
                <img src='/assets/mirati&me.webp' alt="Mirati And Me Logo" />
              </ImageWrapper>
              <BaseText style={{ marginBottom: '2rem', textAlign: 'center' }} size={1.5} weight="700" color="#3A3A3A">Explore patient support services designed to help you guide your patients throughout their treatment journey.</BaseText>

              <Box padding="0 0.8rem" direction="column">
                <AccordionNoArrow setRecalculate={setRecalculate} title="COVERAGE AND ACCESS SUPPORT" icon='/assets/mm_check.webp' alt='Mirati Coverage And Access Support'>
                  <CoverageContent />
                </AccordionNoArrow>

                <AccordionNoArrow setRecalculate={setRecalculate} title="FINANCIAL SUPPORT*" icon='/assets/mm_financial.webp' alt='Mirati Financial Support'>
                  <FinancialContent />
                </AccordionNoArrow>

                <AccordionNoArrow setRecalculate={setRecalculate} title="EDUCATIONAL SUPPORT" icon='/assets/mm_education.webp' alt='Mirati Educational Support'>
                  <EducationalContent />
                </AccordionNoArrow>

                <AccordionNoArrow setRecalculate={setRecalculate} title="EMOTIONAL SUPPORT" icon='/assets/mm_emotional.webp' alt='Mirati Emotional Support'>
                  <EmotionalContent />
                </AccordionNoArrow>

                <AccordionNoArrow setRecalculate={setRecalculate} title="ADDITIONAL SUPPORT" icon='/assets/mm_additional.webp' alt='Mirati Additional Support'>
                  <AdditionalContent />
                </AccordionNoArrow>
              </Box>

              <ButtonWrapper >
                <BaseText style={{ marginBottom: '2rem', textAlign: 'center' }} size={1.5} weight="700" color="#3A3A3A">To learn about these programs and more, <br /> call 1-844-647-2842 (Option 1).</BaseText>
                <Leaving url="https://miratiandme.com"><Button>Visit MiratiAndMe.com</Button></Leaving>
              </ButtonWrapper>
              <BaseText style={{ width: '100%', textAlign: 'left', margin: '0 0 15px 25px' }} size={.9} weight="300" color="#3A3A3A">*Restrictions and conditions apply.</BaseText>
            </CustomWrapper>
          </ContainerScroll>
        </Scroll>
      </CustomContainer>
    )
  }

  return (

    <CustomContainer className={showPageContent ? 'visible' : 'hidden'} id="support-container" isiconfig={isiconfig}>
      <Scroll>
        {/* <ContainerScroll recalculate={recalculate}> */}
        <CustomWrapper className="swiper-no-swiping">
          <ImageBox padding='0 5.3rem' margin="0  0 1rem 0">
            <img src='/assets/mirati&me.webp' alt="Mirati and Me logo" />
          </ImageBox>
          <BaseText style={{ marginBottom: '2rem', textAlign: 'center' }} size={1.5} weight="700" color="#3A3A3A">Explore patient support services designed to help you guide your patients throughout their treatment journey.</BaseText>
          <AcordionContainer>
            <HorizontalAccordion>
              <SupportWrapper className="title desktop" info={<CoverageContent />}>
                <SupportTitle>
                  <img src='/assets/mm_check.webp' alt="Mirati coverage and access support" /> <SupportText as="span">COVERAGE AND ACCESS SUPPORT </SupportText>
                  < FaChevronRight className="accordion-chevron" />
                </SupportTitle>
              </SupportWrapper>

              <SupportWrapper className="title desktop" info={<FinancialContent />}>
                <SupportTitle>
                  <img src='/assets/mm_financial.webp' alt="Mirati financial support" /> <SupportText as="span">FINANCIAL SUPPORT*</SupportText>
                  < FaChevronRight className="accordion-chevron" />
                </SupportTitle>
              </SupportWrapper>

              <SupportWrapper className="desktop title" info={<EducationalContent />}>
                <SupportTitle>
                  <img src='/assets/mm_education.webp' alt="Mirati educational support" /> <SupportText as="span">EDUCATIONAL SUPPORT </SupportText>
                  < FaChevronRight className="accordion-chevron" />
                </SupportTitle>
              </SupportWrapper>

              <SupportWrapper className="desktop title" info={<EmotionalContent />}>
                <SupportTitle>
                  <img src='/assets/mm_emotional.webp' alt="Mirati emotional support" /> <SupportText as="span">EMOTIONAL SUPPORT </SupportText>
                  < FaChevronRight className="accordion-chevron" />
                </SupportTitle>
              </SupportWrapper>

              <SupportWrapper className="desktop title" info={<AdditionalContent />}>
                <SupportTitle>
                  <img src='/assets/mm_additional.webp' alt="Mirati additional support" /> <SupportText as="span">ADDITIONAL SUPPORT </SupportText>
                  < FaChevronRight className="accordion-chevron" />
                </SupportTitle>
              </SupportWrapper>
            </HorizontalAccordion>
          </AcordionContainer>
          <CustomBox height="auto" direction="row" justify="space-between" align="center">
            <BaseText margin="0 1rem 0 0" size={!isMobile ? 2.2 : 1.6} weight="700" color="#3A3A3A">To learn about these programs and more, call 1-844-647-2842 (Option 1).</BaseText>
            <Leaving target="_blank" rel="noreferrer" url="https://miratiandme.com"><Button>Visit MiratiAndMe.com</Button></Leaving>
          </CustomBox>
        </CustomWrapper>
        {/* </ContainerScroll> */}
      </Scroll>
    </CustomContainer>
  )
}

export default Slide1;

const Refs = styled.div`
  font: 12px "Barlow", sans-serif;
  color: #3A3A3A;
  padding-left: 5px;
  margin-top: 15px;

  @media ${device.desktop} {
    margin-top: 0;
  }

  p {
    margin-bottom: 2px
  }

  sup:first-of-type,
  span:first-of-type {
    margin: 0;
    padding: 0;
    width: 5px;
    position: relative;
    display: inline-block;
    margin-left: -5px;
    text-align: right;
  }
`

const CustomBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`
const Scroll = styled.div`
  height: calc(100% - 4.6rem);

  @media ${device.desktop} {
    height: 100%;
  }
`

const CustomContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0;
  max-height: ${props => `calc(100% - ${props.isiconfig})`};
  margin-bottom: 3rem;
`

const CustomWrapper = styled(Wrapper)`
  height: 100%;
  margin-top: 0;
  justify-content: center;
  @media ${device.desktop} {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`

const Button = styled.div`
  font: 1rem "Barlow", sans-serif;
  padding: 0.8rem 3.6rem 1rem;
  background: #3246FF;
  color: white;
  border-radius: 2rem;
  text-align: center;
  cursor: pointer;

  @media ${device.desktop} {
    font-size: 1.7rem;
  }
`

const ImageBox = styled(Box)`
  height: auto;
  img {
    margin-top: 1rem;
    max-width: 28rem;
  }

`

const SupportContainer = styled.div`

  &.financial{}
`

const Content = styled(Box)`
  justify-content: space-between;

  span,
  li {
    color: #3A3A3A;
    font-family: 'Barlow';
    letter-spacing: 0;
    font-weight: 600;
    &.custom-text {
      font-size: 1.2rem;
    }
    .link-out {
      color: #3246FF;
      cursor: pointer;
    }
  }
  ul {
    padding-left: 15px;
  }

  &.educational {
    padding-top: 37%;
  }

  &.emotional {
    padding-top: 52%;
  }

  &.additional {
    padding-top: 60%;
  }

  @media ${device.desktop} {
    span,
    li {
      &.custom-text {
        font-size: 1.6rem;
      }
    }
    ul {
      padding-left: 0;
    }
  }

`

const AcordionContainer = styled(StyledBothSideShape)`
  background: #F2EDF0;
  height: auto;
  width: auto;
  box-shadow: 0 .2rem .5rem -0.1rem rgba(0,0,0,0.2), 0 0.1rem 1.2rem 0 rgba(0,0,0,0.12), 0 .5rem .5rem 0 rgba(0,0,0,0.14);

`

const SupportWrapper = styled.div`
    text-align: left;
    background: #F5F6FC;
    position: relative;
    z-index: 2;
    pointer-events: all;

  ${props => {
    if (!props.open) {
      return 'border-radius: .6rem;'
    }

    return `
        &.title {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        };

        &.container {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          transform: translateY(-0.1rem);
        };
      `
  }}

    &.desktop {
    border: 3px solid transparent;
      background: #E1DAE9;
    }

    &.selected {
        border-color: #009BA6;
        background: transparent;

        svg {
          color: rgba(181,166,203,0.5);
          transform: rotate(180deg);
        }
      }
`

const SupportTitle = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors['dark-blue']};

    svg {
      color: #009BA6;
    }

    .custom-icon {
      width: 1.4rem;
      height: 1.4rem;
    }

    .list-none {
      list-style: none;
    }

    img {
      width: 48px;
      height: auto;
    }

    @media ${device.tablet} {
      font-size: 2rem;
      padding: 1.1rem 3.7rem 1.1rem 1.8rem;

      .custom-icon {
        width: 2rem;
        height: 2rem;
      }
    }

    @media ${device.desktop} {
      font-size: 2.1rem;
    min-height: 70px;
    }
`

const SupportText = styled(BaseText)`
  color: #502D83;
  width: 100%;
  margin-left: 1.4rem;
  text-transform: uppercase;
  font-family: 'Barlow';
  font-size: 2.1rem;
  letter-spacing: 0..5rem;
  line-height: 2.4rem;
`


const ImageWrapper = styled(Box)`
 padding: 2.3rem 5.3rem;
 @media ${device.tablet} {
 padding: 1rem 5.3rem;
  img {
    max-width: 26rem;
  }
 }
`

const ButtonWrapper = styled(FlexCentered)`
  flex-direction: column;
  margin-bottom: 3rem;
  margin-top: 2.2rem;
`

const GreenText = styled(BaseText)`
  font-size: 1.2rem;
  color: #009BA6;
  font-weight: bold;

  @media ${device.desktop} {
    font-size: 1.6rem;
  }
`
