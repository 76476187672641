import useMobileDetect from '../../../_hooks/useMobileDetect'
import React from 'react'
import styled from 'styled-components/macro'
import useAnimateOnScroll from '../../../_hooks/useAnimateOnScroll'
import { device } from '../../_assets/styles/theme'
import { HeaderTitle, ISIWrapper } from './Collapsing'
import IsiContent from './components/IsiContent'
import IndicationContent from './components/IndicationContent'

const StaticIsi = ({ inview }) => {
  const { ref } = useAnimateOnScroll()
  const { isMobile } = useMobileDetect()

  if (isMobile) {
    return (
      <StaticISIWrapper>
        {/* <HeaderWrapper> */}
        <HeaderTitle ref={ref}>
          <div>Indication</div>
        </HeaderTitle>
        <IndicationContent />
        {/* </HeaderWrapper> */}

        {/* <HeaderWrapper> */}
        <HeaderTitle ref={ref}>
          <div>Important Safety Information</div>
        </HeaderTitle>
        <IsiContent />
        {/* </HeaderWrapper> */}
      </StaticISIWrapper>
    )
  }

  return (
    <StaticISIWrapper>
      <HeaderWrapper >
        <HeaderTitle ref={ref}>
          <div>Important Safety Information</div>
        </HeaderTitle>
        <HeaderTitle ref={ref}>
          <div>Indication</div>
        </HeaderTitle>
      </HeaderWrapper>
      <IsiContent />
    </StaticISIWrapper>
  )
}

export default StaticIsi

const StaticISIWrapper = styled(ISIWrapper)`
    position: relative;
    z-index: 9999;
    height: auto;
    padding-bottom: 2rem;
    padding-bottom: 0;

    &.fixed {
      position: fixed;
      height: 35rem;
      padding-bottom: 0;
    }

    @media ${device.desktop} {
        section {
          margin: 0 auto;
          padding: 1.6rem 1.6rem 0;
        }
    }

    @media (min-width: 1260px) and (max-height: 680px) {
       section {
        margin: 1.5rem 15.7rem 0px;
        padding-left: 0;
        padding-right: 0;
       }
    }
`
const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
    padding: 1rem 0;


    img {
        width: 1.6rem;
        height: 1.6rem;
    }

    @media ${device.tablet} {
        margin-bottom: 0px;

        img {
            width: 2rem;
            height: 2rem;
        }
    }

`
