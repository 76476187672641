import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { StyledBothSideShape } from '../PageSwipper/utils/styles'
import Modal from './Modal'
import { device } from '../../_assets/styles/theme'
import { useToggle } from '../../../_hooks/useToggle'

const VideoModal = ({ src, children }) => {

  const [open, toggle] = useToggle(false)

  const handleClick = (e) => {
    e.preventDefault()
    toggle()
  }
  const handleCookieUpdate = (e) => {
    e.preventDefault()
    window.OneTrust && window.OneTrust.ToggleInfoDisplay()
    document.getElementById("ot-header-id-BG33").click()
  }


  return (
    <>
      <a href={src} onClick={handleClick}>{children}</a>
      <Modal isOpen={open}>
        <Container>
          <StyledBothSideShape background="#20293A" border="white" borderSize={.2}>
            <CloseBtnWrapper onClick={toggle}>
              <img src="/assets/closebtn.svg" alt="" />
            </CloseBtnWrapper>

            <Wrapper>
              {window.OnetrustActiveGroups && window.OnetrustActiveGroups.indexOf('C0004') === -1 ?
                <ConsentRequired>
                  <div>Please enable <a href="#" onClick={handleCookieUpdate}>Targeted Advertising Cookies</a> to clear this message and play the video.</div>
                </ConsentRequired> :
                (<><iframe
                  src={src}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullscreen
                  title="MIRMIR_1650053_Main_05_15"></iframe>
                  <script src="https://player.vimeo.com/api/player.js"></script></>)
              }
            </Wrapper>
          </StyledBothSideShape>
        </Container>
      </Modal>
    </>
  )
}

export default VideoModal

const Container = styled.div`
  height: 30rem;
  width: 49.6rem;
  font-family: 'Barlow';
  @media ${device.desktop}{
    height: 40rem;
    width: 80rem;
  }

  > div {
    box-shadow: 0 .2rem 0.4rem -0.1rem rgba(0,0,0,0.2), 0 0.1rem 1rem 0 rgba(0,0,0,0.12), 0 0.4rem .5rem 0 rgba(0,0,0,0.14);
    max-width: 95%;
    margin: 0 auto;

    @media ${device.desktop}{
      max-width: 100%;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2.4rem 1.6rem;
  align-items: center;

  @media ${device.desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 2.8rem 3.8rem;
  }

  padding: 56.25% 0 0 0;
  position: relative;
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
  }

`

const CloseBtnWrapper = styled.div`
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
    z-index: 1;

    img {
      width: 1.6rem;
    }
`

const ConsentRequired = styled.div`
  font-family: "Barlow";
  font-size: 16px;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px 48.3%;
  color: #fff;
  a {
    color: #fff;
    text-decoration: underline;
  }
  div {
    position: absolute;
    width: 100%;
  }
  @media (min-width: 1025px) {
    padding: 0 0 48.3%;
  }
`;
